import React,{useState, useEffect} from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Redirect, useHistory } from 'react-router-dom';
//axios
import axios from 'axios'
//prime message
import { Message } from 'primereact/message';
import api from '../../../../interceptor/axios';

const Login = ({setIsLoggedIn}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [initPassword, setInitPassword] = useState(false);
    const [dni, setDni] = useState('');
    const [redirect, setRedirect] = useState(false);
    let history = useHistory()

    const handleInitPassword = () => {
        if(!dni || !password || !password2){
            setError('Por favor, rellene todos los campos');
        } else {
            //comprobamos que las dos contraseñas coincidan
            if(password !== password2){
                setError('Las contraseñas no coinciden');
            } else {
                 api
                .post(process.env.REACT_APP_REST_URL + 'initPassword.php', { email: email, dni: dni, password: password, password2: password2 })
                .then(response => {
                    if (response.data.success) {
                        setInitPassword(false);
                        setMessage(response.data.message);
                        setError('');
                        setPassword('');
                    } else {
                        setError(response.data.message);
                        setPassword('');
                        setPassword2('');
                    }
                })
                .catch(error => {
                    setError(error.message);
                });
                
            }
        }
    };

    const handleLogin = () => {
        // Lógica para llamar al servicio REST y manejar el inicio de sesión
        setMessage('');
        if(!email || !password){
            setError('Por favor, rellene todos los campos')
        } else {
            api
            .post(process.env.REACT_APP_REST_URL + 'login.php', { email: email, password: password })
            .then(response => {
                if (response.data.success) {
                    //comprobamos si es primer acceso
                    if(response.data.primerAcceso){
                        setInitPassword(true);
                        setPassword('');
                    } else {
                        sessionStorage.setItem('isLoggedIn', true); 
                        sessionStorage.setItem('datosUsuario', response.data.datosUsuario);
                        sessionStorage.setItem('token', response.data.token);
                        setIsLoggedIn(true);
                    }
                } else {
                    setError(response.data.message);
                    setPassword('');
                }
            })
            .catch(error => {
                setError(error.message);
            });
            }
      };

      if (redirect) {
        return <Redirect to="/" />;
      }
    

    if(initPassword){
        return (
            <>
                <section className="sign-in-page">
                    <Container>
                        <Row className="justify-content-center align-items-center height-self-center">
                            <Col lg="5" md="12" className="align-self-center">
                                <div className="sign-user_card ">                    
                                    <div className="sign-in-page-data">
                                        <div className="sign-in-from w-100 m-auto">
                                            <h3 className="mb-3 text-center">Parece que es tu primer acceso a la plataforma</h3>
                                            <p>Es necesario inicializar la contraseña de la siguiente manera:</p>
                                            <ul>
                                                <li>Debe tener entre 8 y 16 caracteres</li>
                                                <li>Debe contener al menos una letra mayúscula</li>
                                                <li>Debe contener al menos una letra minúscula</li>
                                                <li>Debe contener al menos un número</li>
                                                <li>Debe contener al menos un carácter especial: @-_$#*</li>
                                            </ul>
                                            <Form className="mt-4">
                                                <Form.Group>                                 
                                                    <Form.Control value={dni} onChange={(e) => setDni(e.target.value)} type="text" className="form-control mb-0" id="dni" placeholder="DNI sin letra" autoComplete="off" required/>
                                                </Form.Group>
                                                <Form.Group>                                 
                                                    <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control mb-0" id="exampleInputPassword2" placeholder="Password" required/>
                                                </Form.Group>
                                                <Form.Group>                                 
                                                    <Form.Control value={password2} onChange={(e) => setPassword2(e.target.value)} type="password" className="form-control mb-0" id="exampleInputPassword3" placeholder="Repetir password" required/>
                                                </Form.Group>
                                                <div className="sign-info">
                                                    <Button className="btn btn-hover btn-primary1" onClick={handleInitPassword}>Inicializar</Button> 
                                                    {error && (
                                                    <Message severity="error" text={error} />
                                                    )}
                                                </div>                                    
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    } 

    return (
        <>
            <section className="sign-in-page">
                <Container>
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="5" md="12" className="align-self-center">
                            <div className="sign-user_card ">                    
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <h3 className="mb-3 text-center">Bienvenid@</h3>
                                        <Form className="mt-4">
                                            <Form.Group>                                 
                                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control mb-0" id="exampleInputEmail1" placeholder="Email" autoComplete="off" required/>
                                            </Form.Group>
                                            <Form.Group>                                 
                                                <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }} type="password" className="form-control mb-0" id="exampleInputPassword2" placeholder="Password" required/>
                                            </Form.Group>
                                            <div className="sign-info">
                                                <Button className="btn btn-hover btn-primary1" onClick={handleLogin}>Entrar</Button> 
                                                {message && (
                                                <Message severity="success" text={message} />
                                                )}
                                                {error && (
                                                <Message severity="error" text={error} />
                                                )}
                                            </div>                                    
                                        </Form>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex justify-content-center links">
                                        <Link to="/recuperarPassword" className="f-link">
                                            He olvidado mi contraseña
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Login