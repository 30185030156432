import React,{ useState,useRef} from 'react'
import { gsap } from 'gsap'
import { Container,Col,Row,Nav,Tab, } from 'react-bootstrap'
import { ProgressSpinner } from 'primereact/progressspinner';   
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import {descargarPDF} from '../../../utils/descargarPDF';

//axios
import axios from 'axios'
import api from '../../../interceptor/axios';

// swiper
import SwiperCore, { EffectFade,Navigation,Thumbs,Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([EffectFade,Navigation,Thumbs,Pagination]);

const gsapAnimate = {
   getData: (elem) => {
      const option = {
         opacity: 0,
         scale: 1,
         position: {
               x: 0,
               y:0,
         },
         ease: "",
         duration: 1,
         delay: .4,
         rotate: 0
      }
      if(elem !== undefined) {
         option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

         option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

         option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

         option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

         option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

         option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

         option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

         option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

         const setOption = {opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay}

         return setOption
      } else {
         return {opacity: 0}
      }
   },
   onStart : (elem) => {
      
      const setOption = gsapAnimate.getData(elem)

      gsap.from(elem, setOption)

   },

   onEnd : (elem) => {
      
      const setOption = gsapAnimate.getData(elem)
      
      gsap.to(elem, setOption)

   },

   onStartEnd : (elem) => {

      const setOption = gsapAnimate.getData(elem)

      const setEndOption = gsapAnimate.getData(elem)

      setEndOption.opacity = 1

      setEndOption.x = 0

      setEndOption.y = 0

      setEndOption.rotate = 0

      setEndOption.scale = 1

      gsap.fromTo(elem, setOption, setEndOption)
   },
   validValue: (attr, defaultVal) => {
      if (attr !== undefined && attr !== null) {
         return Number(attr)
      }
      return Number(defaultVal)
   }
}

const Tema =()=>{

   const toast = useRef(null);
   const [datos, setDatos] = useState(null);
   const [loading, setLoading] = useState(false);
   const idTema = sessionStorage.getItem('idTema');
   const history = useHistory();

   if(idTema === null){
      history.push('/404');
   }

   const irALeccion = (leccion) => {
      sessionStorage.setItem('idLeccion',leccion.ID);
      history.push('/leccion');
    };

    const descargarTest = async (test) => {
      try{
         await descargarPDF('T', test.ID, test.test_nombre + '.pdf');
      } catch (error) {
         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error al descargar el test' });
      }
    };

    const hacerTest = (test) => {
      setLoading(true);
      api.post(process.env.REACT_APP_REST_URL + "test/test.php", { idTest : test.ID  })
      .then(response => {
          setLoading(false);

          if(response.data.auth !== true){
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            sessionStorage.clear();
            setTimeout(() => {
                history.replace('/login');
            }, 5000);

        } else {
            if (response.data.success) {
                //para cada pregunta, añadimos un campo nuevo
                response.data.test.preguntas.forEach(pregunta => {
                    pregunta.respuestaSeleccionada = 0;
                });
                response.data.test.fechaInicio = new Date().getTime();
                sessionStorage.setItem('test', JSON.stringify(response.data.test));
                history.push('/test');
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
        }
          
      })
      .catch(error => {
          setLoading(false);
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
      });        
    };

   if(datos === null){
      api.post(process.env.REACT_APP_REST_URL + 'tema.php', { idTema: idTema })
      .then(response => {
          
         if (response.data.success) {
            setDatos(response.data);
         } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
         }
         
      })
      .catch(error => {
         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
      });

      return (
         <>
             <Toast ref={toast} />
             <div className="card flex justify-content-center loading">
                 <ProgressSpinner />
             </div>
         </>
     );
   } else {
      if(loading){
         return (
            <div className="card flex justify-content-center loading">
                <ProgressSpinner />
            </div>
        );
      } else {
         return(
            <>
               
               <div className="main-content cabecera-tema" style={{ backgroundImage: `url(${process.env.REACT_APP_URL_PORTADAS + datos.tema.imagen})`  }}>
                  <section>
                     <Container fluid className="position-relative h-100">
                        <div className="slider-inner h-100">
                           <Row className="align-items-center  iq-ltr-direction h-100 ">
                              <Col xl="6" lg="12" md="12">
                                 <h1 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{datos.tema.nombre}</h1>
                              </Col>
                           </Row>
                        </div>
                     </Container>
                  </section>
                  <section>
                     <Container fluid>
                        <Row>
                           <Col sm="12" className="overflow-hidden bg-contenido m-4 p-4">
                              {datos.lecciones.length > 0 && (<>
                                 <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="main-title">Lecciones</h4>
                                 </div>
                                 
                                 {datos.lecciones.map((leccion, index) => (
                                    <div class="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4" key={index}>
                                       <span class="badge badge-secondary font-size-16">{index + 1}</span>
                                       <span class="ml-3 font-Weight-500 genres-info">{leccion.nombre}<br />{leccion.nombreProfesor}</span>
                                       <button type='button' className='ml-4 btn btn-hover iq-button' onClick={() => irALeccion(leccion)}><i className="fa fa-play mr-1" aria-hidden="true"></i>Empezar</button>
                                 </div>
                                 ))}
                              </>)}
                           </Col>
                           <Col sm="12" className="overflow-hidden bg-contenido m-4 p-4">
                              {datos.tests.length > 0 && (<>
                                 <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="main-title">Tests</h4>
                                 </div>

                                 {datos.tests.map((test, index) => (
                                    <div class="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4" key={index}>
                                    <span class="badge badge-secondary font-size-16">{index + 1}</span>
                                    <span class="ml-3 font-Weight-500 genres-info">{test.test_nombre}<br />{test.test_fecha}
                                       {test.ultimaFechaRealizacion !== null && (
                                          <>
                                          <br/>
                                          <span class="font-Weight-500 genres-info">Hecho por última vez el {test.ultimaFechaRealizacion} con una nota de {test.nota}</span>
                                          </>
                                       )}
                                    </span>
                                    
                                       {test.descargable && (
                                          <button type='button' className='btn btn-hover iq-button ml-4' onClick={() => descargarTest(test)}><i className="fa fa-download mr-1" aria-hidden="true"></i>Descargar</button>
                                       )}
                                       {test.online && (
                                          <button type='button' className='btn btn-hover iq-button ml-4' onClick={() => hacerTest(test)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Hacer online</button>
                                       )}
                                    
                                 </div>
                                 ))}
                              </>)}
                           </Col>
                        </Row>
                     </Container>
                  </section>
               </div>
            </>
         )
      }
   }


   
}


export default Tema