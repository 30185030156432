import React,{ useState,useRef} from 'react'
import { Toast } from 'primereact/toast';

const tipoFichero = (extension) => {
    switch (extension) {
        case ".mp4":
            return "video";
        case ".jpeg":
        case ".jpg":
        case ".gif":
            return "imagen";
        case ".pdf":
            return "pdf";
        default:
            return "otro";
    }
};

export const Fichero = ({ bloque, descargar, slug }) => {
    const toast = useRef(null);
    const baseUrl = process.env.REACT_APP_CONTENIDOS_URL.replace("#leccion#", slug);

    return (
        <>
            <Toast ref={toast} />
            <section className='p-2'>
                {bloque.datos.titulo !== "" && <p style={{ fontWeight: 'bold' }}>{bloque.datos.titulo}</p>}
                {bloque.datos.descripcion !== "" && <p>{bloque.datos.descripcion}</p>}
                {tipoFichero(bloque.datos.extension) === "video" && (
                    <div className="embed-responsive embed-responsive-16by9">
                    <video width="100%" controls controlsList="nodownload">
                        <source src={`${baseUrl}${bloque.datos.ruta}`} type="video/mp4" />
                    </video></div>
                )}
                {tipoFichero(bloque.datos.extension) === "imagen" && (
                    <img src={`${baseUrl}${bloque.datos.ruta}`} alt={bloque.datos.descripcion} width="100%" className="img-fluid" />
                )}
                {tipoFichero(bloque.datos.extension) !== "imagen" && tipoFichero(bloque.datos.extension) !== "video" && (
                    <button type='button' className='ml-4 btn btn-hover iq-button' onClick={() => descargar(bloque)}><i className="fa fa-download mr-1" aria-hidden="true"></i>Descargar{bloque.datos.ID_bloque}</button>
                )}
            </section>
        </>
    );
};

export default Fichero;
