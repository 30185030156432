import api from '../interceptor/axios';

export const descargarPDF = async (tipo, id, name) => {

    const generarSlug = (nombre) => {
        return nombre
            .toLowerCase()                         // Convierte a minúsculas
            .replace(/\s+/g, '-')                   // Reemplaza espacios por guiones
            .replace(/[^\w\-]+/g, '')               // Elimina caracteres especiales
            .replace(/\-\-+/g, '-')                 // Reemplaza múltiples guiones consecutivos por uno solo
            .replace(/^-+/, '')                     // Elimina guiones al inicio
            .replace(/-+$/, '');                    // Elimina guiones al final
    };

    let nombreDescarga = generarSlug(name);
   
  try {
        const response = await api.post(process.env.REACT_APP_REST_URL + "descargarPDF.php", { id: id, tipo: tipo }, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url_2 = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url_2;
        link.setAttribute('download', nombreDescarga);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        throw error; // Propaga el error para manejarlo donde sea necesario
    }
};
